import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx"; // Import XLSX for Excel export

const Smsinfo = () => {
  const [sites, setSites] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [duplicates, setDuplicates] = useState([]);

  useEffect(() => {
    fetch("https://heuristic-cray.194-76-27-167.plesk.page/siteinfo")
      .then((response) => response.json())
      .then((data) => setSites(data))
      .catch((error) => console.error("Error fetching site info:", error));
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDuplicatesClick = async (dbName) => {
    try {
      const response = await fetch(`https://heuristic-cray.194-76-27-167.plesk.page/duplicates?dbName=${dbName}`);
      const data = await response.json();

      // Sort duplicates in reverse chronological order based on createdAt
      const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setDuplicates(sortedData);
    } catch (error) {
      console.error("Error fetching duplicate logs:", error);
    }
  };

  const exportToExcel = () => {
    if (duplicates.length === 0) {
      alert("No data available to export.");
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(
      duplicates.map((entry) => ({
        "Created Time": formatDate(entry.createdAt),
        "Patient Name": entry.fullName,
        "Patient Address": entry.address,
        "Log Content": entry.logContent,
      }))
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Duplicates");
    XLSX.writeFile(workbook, "duplicates_report.xls");
  };

  // 🛠 FIXED: Handle Invalid Dates Correctly
  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle missing data

    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return "Invalid Date"; // Handle conversion errors
    }

    return date.toLocaleDateString("en-GB") + " " + date.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" });
  };

  const filteredSites = sites.filter(
    (site) =>
      (site.pharmacyName && site.pharmacyName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (site.pharmacyAddress && site.pharmacyAddress.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div>
      <h2>Site Information</h2>
      <input
        type="text"
        placeholder="Search by site name or address"
        value={searchTerm}
        onChange={handleSearchChange}
        style={{ marginBottom: "10px", padding: "5px", width: "300px" }}
      />

      {/* Sites Table */}
      <div
        style={{
          overflowX: "auto",
          overflowY: "auto",
          maxHeight: "10cm",
          border: "1px solid #ccc",
          marginBottom: "20px",
        }}
      >
        <table border="1" cellPadding="10" style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>Site Name</th>
              <th>Site Address</th>
              <th>DB Name</th>
              <th>Username</th>
              <th>2FA Code</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredSites.map((site, index) => (
              <tr key={index}>
                <td>{site.pharmacyName}</td>
                <td>{site.pharmacyAddress}</td>
                <td>{site.dbName}</td>
                <td>{site.username}</td>
                <td>{site.twoFACode}</td>
                <td>
                  <button onClick={() => handleDuplicatesClick(site.dbName)}>Duplicates</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Duplicates Table */}
      {duplicates.length > 0 && (
        <div>
          <h3>Duplicate Log Entries</h3>
          <button onClick={exportToExcel} style={{ marginBottom: "10px", padding: "5px" }}>
            Export to Excel
          </button>
          <div
            style={{
              overflowX: "auto",
              overflowY: "auto",
              maxHeight: "10cm",
              border: "1px solid #ccc",
            }}
          >
            <table border="1" cellPadding="10" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Created Time</th>
                  <th>Patient Name</th>
                  <th>Patient Address</th>
                  <th>Log Content</th>
                </tr>
              </thead>
              <tbody>
                {duplicates.map((entry, index) => (
                  <tr key={index}>
                    <td>{formatDate(entry.createdAt)}</td>
                    <td>{entry.fullName}</td>
                    <td>{entry.address}</td>
                    <td>{entry.logContent}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default Smsinfo;
