import React, { useState } from "react";

const Login = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
  //  const logEventsUrl = `https://heuristic-cray.194-76-27-167.plesk.page/api/login`;
   // const logEventsResponse = await axios.get(logEventsUrl);
   // const response = await fetch("https://heuristic-cray.194-76-27-167.plesk.page/login", {
    const response = await fetch("https://heuristic-cray.194-76-27-167.plesk.page/login", {
       method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (response.ok) {
      setToken(data.token);
      localStorage.setItem("token", data.token);
    } else {
      alert("Login Failed");
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <input type="text" placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
      <input type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
