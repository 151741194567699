import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'; // Import directly from react-leaflet
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const Map = () => {
  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    const fetchPharmacies = async () => {
      try {
        const response = await fetch('https://heuristic-cray.194-76-27-167.plesk.page/api/pharmacies');
        const data = await response.json();
        setPharmacies(data);
      } catch (error) {
        console.error('Error fetching pharmacies:', error);
      }
    };

    fetchPharmacies();
  }, []);

  return (
    <div style={{ height: '600px', width: '100%' }}>
      <h2>Map of Active Pharmacies</h2>
      <MapContainer center={[54.0, -2.0]} zoom={6} style={{ height: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {pharmacies.map((pharmacy, index) => {
          if (pharmacy.location) {
            return (
              <Marker
                key={index}
                position={[pharmacy.location.lat, pharmacy.location.lng]}
                icon={new L.Icon({
                  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                  iconSize: [25, 41],
                  iconAnchor: [12, 41],
                  popupAnchor: [1, -34],
                })}
              >
                <Popup>
                  <div>
                    <strong>{pharmacy.name}</strong>
                    <br />
                    {pharmacy.address}
                    <br />
                    {pharmacy.postcode}
                  </div>
                </Popup>
              </Marker>
            );
          }
          return null;
        })}
      </MapContainer>
    </div>
  );
};

export default Map;
