import React, { useState, useEffect } from "react";

const Reports = ({ token }) => {
  const [reports, setReports] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState(""); // Added search term state

  const getDefaultDates = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear()).slice(-2); 

      return `${day}/${month}/${year}`;
    };

    return {
      from: formatDate(today),
      to: formatDate(tomorrow),
    };
  };

  useEffect(() => {
    const { from, to } = getDefaultDates();
    setFromDate(from);
    setToDate(to);
  }, []);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === "fromDate") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  const fetchReports = async () => {
    if (!fromDate || !toDate) {
      alert("Please provide both from and to dates.");
      return;
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    if (!formattedFromDate || !formattedToDate) {
      alert("Invalid date format. Please use dd/mm/yy.");
      return;
    }

    try {
      const response = await fetch(`https://heuristic-cray.194-76-27-167.plesk.page/analyze?fromDate=${formattedFromDate}&toDate=${formattedToDate}`);
      const data = await response.json();
      
      if (data && data.gridData) {
        setReports(data.gridData);
      } else {
        console.error("No gridData found in response");
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const formatDate = (date) => {
    const [day, month, year] = date.split("/");
    if (!day || !month || !year || year.length !== 2) {
      return null;
    }
    const fullYear = `20${year}`;
    return `${fullYear}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  };

  const calculateTotals = () => {
    const totals = {
      prescriptions: 0,
      patients: 0,
      putOnShelf: 0,
      deliveries: 0,
      smsSent: 0,
      emailsSent: 0,
      nearMisses: 0,
      walkIns: 0,
    };

    reports.forEach(report => {
      totals.prescriptions += report[1] || 0;
      totals.patients += report[2] || 0;
      totals.putOnShelf += report[3] || 0;
      totals.deliveries += report[4] || 0;
      totals.smsSent += report[5] || 0;
      totals.emailsSent += report[6] || 0;
      totals.nearMisses += report[7] || 0;
      totals.walkIns += report[8] || 0;
    });

    return totals;
  };

  const totals = calculateTotals();

  // Filter reports based on search term
  const filteredReports = reports.filter(report =>
    report[0].toLowerCase().includes(searchTerm.toLowerCase()) // Filter by Site Name
  );

  return (
    <div>
      <h2>Activity Report</h2>

      <div>
        <label>From: </label>
        <input
          type="text"
          name="fromDate"
          value={fromDate}
          onChange={handleDateChange}
          placeholder="dd/mm/yy"
        />
      </div>
      <div>
        <label>To: </label>
        <input
          type="text"
          name="toDate"
          value={toDate}
          onChange={handleDateChange}
          placeholder="dd/mm/yy"
        />
      </div>
      <button onClick={fetchReports}>Refresh Reports</button>

      {/* Search Box */}
      <div style={{ marginTop: "20px" }}>
        <label>Search Site: </label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter site name..."
        />
      </div>

      {filteredReports.length > 0 && (
        <div>
          <h3>Report Data</h3>
          <table border="1" cellPadding="10" style={{ marginTop: "20px", width: "100%" }}>
            <thead>
              <tr>
                <th>Site Name</th>
                <th>Handed out</th>
                <th>New pts</th>
                <th>Imported SMS</th>
                <th>Stacked</th>
                <th>Dels</th>
                <th>SMS Sent</th>
                <th>Emails Sent</th>
                <th>Near Misses</th>
                <th>Walk-ins</th>
                <th>NHS no %</th>
    
              </tr>
            </thead>
            <tbody>
              {filteredReports.map((report, index) => (
                <tr key={index}>
                  <td>{report[0]}</td> {/* Site Name */}
                  <td>{report[1]}</td> {/* Prescriptions Data */}
                  <td>{report[2]}</td> {/* Patients Data */}
                  <td>{report[10]}</td> {/* Imported SMS Count */}
                  <td>{report[3]}</td> {/* Put on Shelf */}
                  <td>{report[4]}</td> {/* Deliveries */}
                  <td>{report[5]}</td> {/* SMS Sent */}
                  <td>{report[6]}</td> {/* Emails Sent */}
                  <td>{report[7]}</td> {/* Near Misses */}
                  <td>{report[8]}</td> {/* Walk-ins */}
                  <td>{Math.round(report[9])}</td> {/* NHS Percentage */}
          
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th>Total</th>
                <td>{totals.prescriptions}</td>
                <td>{totals.patients}</td>
                <td>{  }</td>
                <td>{totals.putOnShelf}</td>
                <td>{totals.deliveries}</td>
                <td>{totals.smsSent}</td>
                <td>{totals.emailsSent}</td>
                <td>{totals.nearMisses}</td>
                <td>{totals.walkIns}</td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}
    </div>
  );
};

export default Reports;
