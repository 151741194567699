import React, { useState } from "react";
import Login from "./Login";
import Reports from "./Reports";
import Sitesinfo from "./Sitesinfo";
import Smsinfo from "./Smsinfo";
import Map from "./Map"; // Assuming the Map component is in the same directory
import Importfailures from "./Importfailures"; // Assuming the Duplicates component is in the same directory

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [currentView, setCurrentView] = useState("reports"); // Default view is "reports"

  // Function to switch the current view
  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  return (
    <div>
      {!token ? (
        <Login setToken={setToken} />
      ) : (
        <div>
          <div>
            <button onClick={() => handleViewChange("reports")}>Reports</button>
            <button onClick={() => handleViewChange("map")}>Map of active sites</button>
            <button onClick={() => handleViewChange("importfailures")}>Importfailures</button>
            <button onClick={() => handleViewChange("sitesinfo")}>Sitesinfo</button>
            <button onClick={() => handleViewChange("smsinfo")}>Smsinfo</button>
          </div>
          
          {currentView === "reports" && <Reports token={token} />}
          {currentView === "map" && <Map />}
          {currentView === "importfailures" && <Importfailures />}
          {currentView === "sitesinfo" && <Sitesinfo />}
          {currentView === "smsinfo" && <Smsinfo />}
        </div>
      )}
    </div>
  );
};

export default App;
