import React, { useEffect, useState } from 'react';

const ImportFailures = () => {
    const [gridData, setGridData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [date, setDate] = useState(""); // State to hold the selected date

    // Fetch the grid data when the date is selected and the button is clicked
    const fetchImportFailures = async () => {
        if (!date) {
            alert("Please select a date");
            return;
        }

        // Format the date as YYYY-MM-DD for the query string
        const dateFrom = new Date(date).toISOString().split('T')[0]; // Get just the date part

        console.log("Formatted Date:", dateFrom); // Log the date to see it

        try {
            const response = await fetch(`https://heuristic-cray.194-76-27-167.plesk.page/importfailures?dateFrom=${dateFrom}`);
            const data = await response.json();

            // Sort the gridData in reverse chronological order based on the date (assuming the date is in the 5th column)
            const sortedData = data.gridData.sort((a, b) => new Date(b[4]) - new Date(a[4]));
            setGridData(sortedData);
            setFilteredData(sortedData); // Initialize filtered data with all entries
        } catch (error) {
            console.error('Error fetching import failures:', error);
        }
    };

    // Filter grid data based on the search term
    useEffect(() => {
        const filtered = gridData.filter((row) => {
            const name = row[1].toLowerCase();
            return name.includes(searchTerm.toLowerCase());
        });
        setFilteredData(filtered);
    }, [searchTerm, gridData]);

    return (
        <div>
            <h1>Import Failures</h1>

            {/* Date Picker for Date */}
            <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ marginBottom: '20px', padding: '5px' }}
            />

            {/* Button to trigger the fetch */}
            <button onClick={fetchImportFailures} style={{ marginBottom: '20px', padding: '10px' }}>
                Load Import Failures
            </button>

            {/* Search Box */}
            <input
                type="text"
                placeholder="Search by name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ marginBottom: '20px', padding: '5px', width: '200px' }}
            />

            {/* Table Grid */}
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Pharmacy Name</th>
                        <th>Name</th>
                        <th>Contact Number</th>
                        <th>Pre-Registry Name</th>
                        <th>Address</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredData.length > 0 ? (
                        filteredData.map((row, index) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>{row[0]}</td>
                                    <td>{row[1]}</td>
                                    <td>{row[4]}</td> {/* Moved Contact Number Next to Name */}
                                    <td>{row[2]}</td>
                                    <td>{row[3]}</td>
                                    <td>{row[5]}</td>
                                </tr>
                                <tr>
                                    <td colSpan="6" style={{ borderBottom: '2px solid #ccc' }}></td>
                                </tr>
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" style={{ textAlign: 'center' }}>
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default ImportFailures;
